<script>
  /**
   * A progress bar with an optional label and icon above it.
   */
  export default {
    props: {
      /** The current value, which should be between 0 and the target value (inclusively). */
      value: {
        type: Number,
        required: true,
      },
      /** The target value, i.e. the number appearing on the right side of the progress bar. */
      targetValue: {
        type: Number,
        required: true,
      },
      /** The color scheme for the progress bar. */
      colorScheme: {
        type: String,
        default: 'primary',
        validator: (value) => ['primary', 'green'].includes(value),
      },
      /** The text alignment of the label. */
      textAlignment: {
        type: String,
        default: 'left',
        validator: (value) => ['left', 'center'].includes(value),
      },
    },
    computed: {
      progress() {
        return Math.max(0, Math.min(1, this.value / this.targetValue))
      },
    },
  }
</script>

<template>
  <div>
    <!-- for purgecss: text-primary text-green -->
    <div
      v-if="$scopedSlots.default"
      :class="`mb-2 text-${colorScheme} leading-tight flex flex-wrap ${
        textAlignment === 'center' ? 'justify-center' : ''
      } `"
    >
      <div>
        <BaseIcon
          v-if="$scopedSlots.icon"
          :size="5"
          :class="`mr-1 -my-1 inline-block align-middle`"
          style="top: -0.1em"
        >
          <!-- @slot Optional. An icon shown on the left of the label. Should contain an SVG icon component. -->
          <slot name="icon" />
        </BaseIcon>
        <span class="font-semibold">
          <!-- @slot Optional. The label to display above the progress bar. Should contain text. -->
          <slot />
        </span>
      </div>
      <div v-if="$scopedSlots.sublabel" class="text-xs py-1">
        <!-- @slot Optional. Sublabel to go under main text if needed. Should contain text. -->
        <slot name="sublabel" />
      </div>
    </div>
    <div :class="`h-2 rounded-full`">
      <!-- for purgecss: border-primary border-green -->
      <div :class="`absolute inset-0 rounded-full border border-${colorScheme}`" />
      <!-- for purgecss: from-primary-highlight to-primary from-green-highlight to-green -->
      <div
        :class="`absolute left-0 inset-y-0 rounded-full bg-gradient-to-r from-${colorScheme}-highlight to-${colorScheme} transition-width duration-600`"
        :style="{ width: `${progress * 100}%` }"
      />
    </div>
  </div>
</template>
